import React from 'react';
import { Typography, Button, Box, Card, Slide } from '@mui/material';
import { Carousel } from '../components/Carousel'
import Grid from '@mui/material/Grid2';
import Opening from "../assets/opening.jpg";
import Using from "../assets/using.jpg"
import Adding from "../assets/adding.jpg"

import AddIcon from '@mui/icons-material/Add';

export function InstructionsPage() {
    const instructions = {
        0: {
            title: "Opening the Extension",
            text: '<p>Click on the <img className="logo" src="../assets/icon.svg" alt="icon"/> icon to open the Knoll extension.</p><p>You will already have the "Welcome to Knoll" Module on your account.</p>',
            image: Opening
        }, 
        1: {
            title: "Using Modules",
            text: 'To use a module, check the box next to the module and click "Save".<p>Then, if you navigate to ChatGPT, the information from that module will now be directly integrated.</p>',
            image: Using
        }, 
        2: {
            title: "Adding New Modules",
            text: "<p>To add a module, click on the + button on the upper left corner of your extension.</p><p>You can add the link to your module's Markdown file.</p>",
            image: Adding
        }, 
        3: {
            title: "More Questions?",
            text: 'If you have more questions, check the "Welcome to Knoll" module and start chatting with ChatGPT!'
        }
    }
    return (
        <Box>
            <Grid container justifyContent={'flex-start'}>
                <Grid size={6}>
                    
                    <Typography variant="h6">
                        Start integrating modules into your ChatGPT experience today. 
                    </Typography>
                    <br/>
                    <Carousel slides={instructions}/>
                    {/* <Card>
                        Click on the icon to open the Knoll extension. You will already have the "Welcome to Knoll" Module on your account. 
                    </Card>
                    <Card>
                        To use a module, check the box next to the module and click "Save." Then, if you navigate to ChatGPT, the informatioin from that module 
                        will now be directly integrated.
                    </Card>
                    <Card>
                        To add a module, click on the + button on the upper left corner of your extension. You can add the link to your module's Markdown file.  
                    </Card>
                    <Card>
                        If you have more questions, check the "Welcome to Knoll" module and start chatting with ChatGPT!
                    </Card> */}
                </Grid>
            </Grid>
        </Box>
    );

}