import React, { useState } from "react";
import {
  useNavigate
} from "react-router-dom";
import { useSelector } from 'react-redux'
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, IconButton, Button, AppBar, Toolbar, Typography, Menu, MenuItem } from "@mui/material";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { signOut} from "firebase/auth";
import { auth } from '../firebase';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from "../assets/logo.svg"
import constants from "../constants";
import './module.css'; 

export function NavBar(props) {
  const userInfo = useSelector((state) => (state.user))
  let substringName = undefined
  try {
    substringName = userInfo?.info?.name.substring(0, 1)
  } catch {
    substringName = undefined
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const handleChat = () => {
    navigate('/chat')
  }

  const handleSettings = () => {
    navigate('/explore')
  }

  const handleLogin = () => {
    navigate('/login')
  }

  const handleAdd = () => {
    navigate('/add')
  }

  const signOutGithub = () => {
    console.log('Chrome id', constants.CHROME_ID)
    signOut(auth).then(() => {
         console.log('Signed out')
         navigate('/logout')
         chrome.runtime.sendMessage(
             constants.CHROME_ID,
             { type: "sign_out" }
         );
    }).catch((error) => {
        console.log(error)
    });
};

  return (
    <Box>
       <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar   
            sx={{
              "@media (min-width: 0px)": { paddingRight: 0, paddingLeft: 0 },
            }} 
          >
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={() => navigate('/')}>
                <img className="logo" src={Logo} alt="logo" />&nbsp;<strong> Knoll</strong>
             </Typography>
            {/* { userInfo?.info.uid ? (
                <IconButton style={{color:"bababa"}} onClick={handleChat}>
                  <ChatIcon/>
                  <Typography variant="body1">
                    &nbsp;Chat
                  </Typography>
                </IconButton>
              ):null
            } */}
            <IconButton 
              disableRipple
              disableFocusRipple
              className="navExplore" 
              onClick={handleSettings}
            >
                <TravelExploreIcon/>
                <Typography variant="body1">
                  &nbsp; Explore Modules
                </Typography>
            </IconButton>
            {/* { userInfo?.info.uid ? 
               <Button 
                  variant="contained"
                  color="success" 
                  startIcon={<LibraryBooksIcon/>}
                  sx={{margin: "0 1em"}}
                  onClick={handleAdd}
                >
                  Add Module
              </Button>
              : null
            } */}
            { userInfo?.info.uid ? (
              <Box sx={{marginLeft: "1em"}}>
                <Avatar
                  className="avatarButton"
                  sx={{ width: 36, height: 36, bgcolor: "#7091E6" }}
                  onClick={handleClick}
                  alt={userInfo?.info?.name}
                >
                  {substringName}
                </Avatar>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {/* <MenuItem onClick={signOutGithub}>
                    <ListItemIcon>
                      <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      Profile
                    </ListItemText>
                  </MenuItem>
                  <Divider/> */}
                  <MenuItem onClick={signOutGithub}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      Sign out
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
              ):null
            }
            { userInfo?.info.uid ? null : (
              <Button sx={{margin: '0 1em'}} onClick={handleLogin} variant="outlined">
                Log In
              </Button>
            )}
            { userInfo?.info.uid ? null : (
              <Button 
                variant="contained"
                onClick={() => navigate('/signup')}
              >
                Join Now
              </Button>
            )}
          </Toolbar>
        </AppBar>
    </Box>
  );
}
