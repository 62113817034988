import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import { Typography, Button, Box, Paper, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux'
import constants from '../constants'
import axios from 'axios'; 
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export function NewModulePage() {
    const userInfo = useSelector((state) => (state.user))
    const [message, setMessage] = useState("")
    const [success, setSuccess] = useState("")
    const [repo, setRepo] = useState("")
    const [link, setLink] = useState("")
    const [llmHelper, setLLMHelper] = useState("")
    const [repoHelper, setRepoHelper] = useState("")

    function endsWithLLM() {
        if (link.endsWith('.llm')) {
            return ""
        } else if (link.length <= 4) {
            return "Check formatting of length"
        } else {
            return "Link does not end with LLM"
        }
    }

    function checkPath() {
        console.log('Path', link.slice(8,))
        if (!link.startsWith('https://github.com')) {
            return "Repo must be a link to Github"
        } else if (link.slice(8,).split('/').length <= 2) {
            return "Repo link formatted incorrectly"
        } else {
            return ""
        }
        
    }

    function preprocessRepo(repo) {
        return repo.replace(/\/$/, '')
    }

    const handleSubmit = () => {
        console.log(repo, link)
        const llmMessage = endsWithLLM()
        const repoMessage = checkPath()
        setLLMHelper(llmMessage)
        setRepoHelper(repoMessage)
        
        if (llmMessage.length === 0 && repoMessage.length === 0) {
            const data = {
                llmLink: link,
                user: userInfo.info,
            }
            axios.post(`${constants.SERVER_API}/createModule`, data)
            .then(response => {
                console.log('Response', response)
                if (response.data) {
                    setSuccess(response.data.success ? "success" : "error")
                    setMessage(response.data.message)
                }
            })
        } else {
            console.log(llmMessage, repoMessage)
            setSuccess("error")
            setMessage("Check the module you are inputting again before submitting.")
        }
    }

    return (
        <Box>
            <Grid sx={{padding: "4em 0"}} container justifyContent="center">
                <Grid size={8}>
                    <Paper sx={{padding: "1em"}} elevation={3}>
                        <Typography variant="h6">
                            <strong>
                                Add a New Module
                            </strong>
                        </Typography>
                        <Grid container direction="column">
                            {/* <Box sx={{display: 'flex', alignItems: "center"}}>
                                <Grid size={10}>
                                <TextField 
                                    sx={{width: "100%"}} 
                                    id="outlined-basic" 
                                    label="Link to Github Repo" 
                                    variant="outlined" 
                                    value={repo}
                                    onChange={(event) => {
                                        setRepo(event.target.value);
                                    }}
                                    helperText={repoHelper}
                                />
                                </Grid>
                                <Grid>
                                    <Tooltip title="Link to the Github repo where your .llm file is" placement="right">
                                        <IconButton>
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Box> */}
                            <Box sx={{display: 'flex', alignItems: "center"}}>
                                <Grid sx={{margin: "0.5em 0"}} size={10}>
                                    <TextField 
                                        sx={{width: "100%"}}
                                        id="outlined-basic"
                                        label="URL of .llm File" 
                                        variant="outlined" 
                                        value={link}
                                        onChange={(event) => {
                                            setLink(event.target.value);
                                        }}
                                        helperText={llmHelper}
                                    />
                                </Grid>
                                <Grid>
                                    <Tooltip title="The .llm file should have the information about your module. It should be in the top level of your Github repo."
                                        placement="right"
                                    >
                                        <IconButton>
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Box>
                            <Grid sx={{margin: "0 0"}} size={4}>
                                {
                                    (link.length === 0) ? (
                                        <Button 
                                        onClick={handleSubmit} 
                                        variant="contained"
                                        disabled
                                        >
                                            Enter
                                        </Button>
                                    ) : (
                                        <Button 
                                            onClick={handleSubmit} 
                                            variant="contained"
                                        >
                                            Enter
                                        </Button>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    {success ? (
                        <Alert sx={{margin: "1em 0"}} severity={success}>
                            {
                                success === "error" ? (
                                    <div>{message}</div>
                                ) : (
                                    <div>Module {message.name} created.</div>
                                )
                            }
                        </Alert>
                    ): <span/>}
                </Grid>
            </Grid>
        </Box>
    );

}