import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';


export function ConfirmPage() {

    return (
        <Box>
            <Grid container justifyContent={'flex-start'}>
                <Grid size={6}>
                    <Typography variant="h6">
                        You have been successfully logged in.<br/>
                        Use the Chrome extension to use Knoll.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );

}