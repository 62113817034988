import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import constants from "../constants";
import { signInWithPopup, GithubAuthProvider } from "firebase/auth";
import { auth, provider } from '../firebase';
import axios from 'axios'; 
import GitHubIcon from '@mui/icons-material/GitHub';

export function LoginButton(props) {
    const navigate = useNavigate();
    const loginWithGitHub = () => {
        signInWithPopup(auth, provider)
            .then(function (result) {
            const credential = GithubAuthProvider.credentialFromResult(result);
            const token = credential?.accessToken;
            const user = result.user;
            const data = {
                uid: user.uid,
                displayName: user.displayName, 
                githubID: user.providerData[0].uid,
            }
            axios.post(`${constants.SERVER_API}/createUser`, data, {
                headers: {
                    Authorization: `${token}`
                }
            })
            .then(result => {
                // send message to extension
                try {
                    chrome.runtime.sendMessage(
                        constants.CHROME_ID,
                        { user: user.uid, type: "sign_in" },
                        function(response) {
                        console.log(response)
                        }
                    );
                } catch(error) {
                    console.error(error)
                }
                if (props.isNew) {
                    navigate('/instructions')
                } else {
                    navigate('/login-confirmation')
                }
            })
            // The signed-in user info.
        })
        .catch(function (error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            const credential = error.credential;
            if (errorCode === 'auth/account-exists-with-different-credential') {
                alert(
                'You have already signed up with a different auth provider for that email.',
                );
                // If you are using multiple auth providers on your app you should handle linking
                // the user's accounts here.
            } else {
                console.error(error);
            }
            });
    };

    
    return (
        <Button 
            sx={{borderRadius:"12px", backgroundColor: "#202124", marginTop: "2em"}}
            variant="contained"
            endIcon={<GitHubIcon/>}
            onClick={loginWithGitHub}
            variant="contained"
        >
            {props.text}
        </Button>
    )
}


// import { initializeApp } from 'firebase/app';
// import {
//   GithubAuthProvider,
//   connectAuthEmulator,
//   getAuth,
//   onAuthStateChanged,
//   signInWithPopup,
//   signOut,
// } from 'firebase/auth';
// import { firebaseConfig } from './config';

// initializeApp(firebaseConfig);

// const auth = getAuth();

// if (window.location.hostname === 'localhost') {
//   connectAuthEmulator(auth, 'http://127.0.0.1:9099');
// }

// const oauthToken = document.getElementById(
//   'quickstart-oauthtoken',
// )! as HTMLDivElement;
// const signInButton = document.getElementById(
//   'quickstart-sign-in',
// )! as HTMLButtonElement;
// const signInStatus = document.getElementById(
//   'quickstart-sign-in-status',
// )! as HTMLSpanElement;
// const accountDetails = document.getElementById(
//   'quickstart-account-details',
// )! as HTMLDivElement;

// /**
//  * Function called when clicking the Login/Logout button.
//  */
// function toggleSignIn() {
//   if (!auth.currentUser) {
//     const provider = new GithubAuthProvider();
//     provider.addScope('repo');
//     signInWithPopup(auth, provider)
//       .then(function (result) {
//         const credential = GithubAuthProvider.credentialFromResult(result);
//         // This gives you a GitHub Access Token. You can use it to access the GitHub API.
//         const token = credential?.accessToken;
//         // The signed-in user info.
//         const user = result.user;
//         oauthToken.textContent = token ?? '';
//       })
//       .catch(function (error) {
//         // Handle Errors here.
//         const errorCode = error.code;
//         const errorMessage = error.message;
//         // The email of the user's account used.
//         const email = error.email;
//         // The firebase.auth.AuthCredential type that was used.
//         const credential = error.credential;
//         if (errorCode === 'auth/account-exists-with-different-credential') {
//           alert(
//             'You have already signed up with a different auth provider for that email.',
//           );
//           // If you are using multiple auth providers on your app you should handle linking
//           // the user's accounts here.
//         } else {
//           console.error(error);
//         }
//       });
//   } else {
//     signOut(auth);
//   }
//   signInButton.disabled = true;
// }

// // Listening for auth state changes.
// onAuthStateChanged(auth, function (user) {
//   if (user) {
//     // User is signed in.
//     const displayName = user.displayName;
//     const email = user.email;
//     const emailVerified = user.emailVerified;
//     const photoURL = user.photoURL;
//     const isAnonymous = user.isAnonymous;
//     const uid = user.uid;
//     const providerData = user.providerData;
//     signInStatus.textContent = 'Signed in';
//     signInButton.textContent = 'Sign out';
//     accountDetails.textContent = JSON.stringify(user, null, '  ');
//   } else {
//     // User is signed out.
//     signInStatus.textContent = 'Signed out';
//     signInButton.textContent = 'Sign in with GitHub';
//     accountDetails.textContent = 'null';
//     oauthToken.textContent = 'null';
//   }
//   signInButton.disabled = false;
// });

// signInButton.addEventListener('click', toggleSignIn, false);