import React, {useState} from "react";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Icon from "../assets/icon.svg"
import './carousel.css'; 

export function Carousel(props) {
    const [page, changePage] = useState(0)
    const numSlides = Object.keys(props.slides).length
    return (
        <Paper className="carousel" elevation={3}>
            <Grid sx={{padding: '1em 0'}} container justifyContent={'space-between'}> 
                <IconButton 
                    className="iconButton" 
                    disabled={page === 0} 
                    onClick={() => changePage(page > 0 ? page - 1: page)}
                    disableRipple
                    disableFocusRipple
                >
                    <ChevronLeftIcon/>
                </IconButton>
                <Grid size={8}>
                    {
                        page === 0 ? (
                            <Box sx={{maxHeight: "75vh", overflowY: "scroll"}}>
                                <Typography variant="h6" sx={{marginBottom: "0.5em"}}>
                                    <strong>
                                        {props.slides[page].title ? props.slides[page].title : ""}
                                    </strong>
                                </Typography>
                                <Typography variant="body">
                                    <p>Click on the <img className="logo" src={Icon} alt="icon"/> icon to open the Knoll extension.</p><p>You will already have the "Welcome to Knoll" Module on your account.</p>
                                </Typography>
                                {
                                    props.slides[page].image ? (
                                        <img className="introImage" src={props.slides[page].image}/>
                                    ) : null
                                }
                            </Box>
                        ) : (
                            <Box sx={{maxHeight: "75vh", overflowY: "scroll"}}>
                                <Typography variant="h6" sx={{marginBottom: "0.5em"}}>
                                    <strong>
                                        {props.slides[page].title ? props.slides[page].title : ""}
                                    </strong>
                                </Typography>
                                <Typography variant="body" dangerouslySetInnerHTML={{ __html: props.slides[page].text }}/>
                                {
                                    props.slides[page].image ? (
                                        <img className="introImage" src={props.slides[page].image}/>
                                    ) : null
                                }
                            </Box>
                        )
                    }
                </Grid>
                <IconButton 
                    className="iconButton" 
                    disableRipple
                    disableFocusRipple
                    disabled={page === numSlides - 1} 
                    onClick={() => changePage(page < numSlides ? page + 1: page)}
                >
                    <ChevronRightIcon/>
                </IconButton>
            </Grid>
        </Paper>
    )
}

