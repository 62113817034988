import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate } from "react-router-dom";

import "./homepage.css"

export function HomePage() {
    const navigate = useNavigate();

    const handleDownload = () => {
        window.open('https://chromewebstore.google.com/detail/knoll/fmboebkmcojlljnachnegpbikpnbanfc?hl=en-US&utm_source=ext_sidebar', '_blank');
    };
    return (
        <Box sx={{padding: '2em 0'}}>
            <Grid container justifyContent={'center'}>
                <Grid size={8} alignItems={'center'}>
                    <Typography sx={{textAlign: "center"}} variant="h2">
                        <strong>
                            Building Collective Knowledge<br/> for LLMs
                        </strong>
                    </Typography>
                </Grid>
            </Grid>
            <Grid sx={{marginTop: "2em"}} container justifyContent={'center'}>
                <Grid size={7} alignItems={'center'}>
                    <Typography sx={{textAlign: "center"}} variant="h6">
                        Knoll is a research prototype that supports using and creating dynamic, localized knowledge repositories for LLMs.
                    </Typography>
                </Grid>
            </Grid>
            <Grid className="buttonContainer" container justifyContent={'center'}>
                <Button className="explore" variant="outlined" onClick={() => navigate('/explore')}>
                    Start Exploring →
                </Button>
            </Grid>
            <Grid className="buttonContainer" container justifyContent={'center'}>
                <Button className="download" variant="contained" onClick={() => handleDownload()}>
                    Download Knoll
                </Button>
            </Grid>
        </Box>
    );

}